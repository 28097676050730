import React, { useState } from "react"

import { graphql } from "gatsby"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import SafeAreaWrapper from "../components/SafeAreaWrapper"
import Introduction from "../components/Introduction"

import Grid from "../components/Grid"
import { Button } from "../components/Button"

// import Hero from "../components/Hero"
import Stage from "../components/Stage"
//import SmallStage from "../components/SmallStage"
import Title from "../components/Title"

import { BlueBanner } from "../components/Banner"

import slug from "slug"
import scrollTo from "gatsby-plugin-smoothscroll"

import { ArrowheadFatDown, Faq, Mail } from "../components/Icon"

const IntegrationsPage = ({ data: { page, integrations } }) => {
	// const domRef = React.useRef()
	// React.useEffect(() => {
	// 	const observer = new IntersectionObserver(
	// 		entries => {
	// 			document.querySelectorAll("aside nav li button").forEach(element => {
	// 				element.classList.remove("active")
	// 			})

	// 			let sortedEntries = entries.sort(
	// 				(a, b) => a.intersectionRatio - b.intersectionRatio,
	// 			)
	// 			if (sortedEntries.length > 0) {
	// 				let entry = sortedEntries[0]

	// 				const id = entry.target.getAttribute("id")
	// 				if (entry.isIntersecting) {
	// 					document
	// 						.querySelector(`aside nav li button[href="#${id}"]`)
	// 						.classList.add("active")
	// 				}
	// 			}

	// 			// for (const entry of entries) {
	// 			// 	const id = entry.target.getAttribute("id")

	// 			// 	// console.log(`${entry?.target?.id} is in view: ${entry.isIntersecting}`)

	// 			// 	// entry.intersectionRatio > 0
	// 			// 	if (entry.isIntersecting) {
	// 			// 		document
	// 			// 			.querySelector(`aside nav li button[href="#${id}"]`)
	// 			// 			.classList.add("active")
	// 			// 	}
	// 			// 	// else {
	// 			// 	// 	document
	// 			// 	// 		.querySelector(`aside nav li button[href="#${id}"]`)
	// 			// 	// 		.classList.remove("active")
	// 			// 	// }
	// 			// }
	// 		},
	// 		{
	// 			threshold: 0,
	// 		},
	// 	)

	// 	// Track all sections that have an `id` applied
	// 	document.querySelectorAll("section[id]").forEach(section => observer.observe(section))
	// 	// observer.observe(domRef.current)

	// 	return () => {
	// 		document.querySelectorAll("section[id]").forEach(section => observer.unobserve(section))
	// 	}
	// }, [])

	return (
		<Layout translucent={false}>
			<Seo
				title={page?.seo?.title || "Interfaces and Integrations"}
				description={
					page?.seo?.description ||
					"Explore the apps that integrate with CFM to expand your workflow and take your processes to the next level."
				}
				keywords={page?.seo?.keywords || ""}
			/>

			<Stage name="hero" className="overflow-visible" lightBackground={true}>
				<div className="xl:container px-10 xl:px-30 mt-60 md:mt-80">
					<div className="px-10 md:px-20">
						<div className="grid grid-cols-12 gap-x-20 md:gap-x-40 gap-y-50">
							<div className="h-max col-span-12 md:col-start-2 md:col-end-12 lg:col-span-5 xl:col-start-2 xl:col-span-4 flex flex-col justify-center  md:max-w-none xl:mr-24 self-center ">
								<h1
									className={`mt-80 md:mt-100 lg:mt-120 display1 text-left text-jetblack`}>
									{page?.seo?.title}
								</h1>

								<p className="mb-80 md:mb-100 lg:mb-120 mt-20 text-left text-nevada">
									{page?.seo?.description}
								</p>
							</div>

							<div className="hidden lg:col-span-7 xl:col-start-7 lg:flex flex-col items-center justify-center">
								<div className="lg:absolute">
									<StaticImage
										width={695}
										src="../images/interfaces-and-integrations.png"
										alt="Cloud Fleet Manager Interfaces and Integrations"
										title="Cloud Fleet Manager Interfaces and Integrations"
										className="w-full h-full object-cover"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Stage>

			<div className="my-spacer-xl">
				<SafeAreaWrapper>
					<Introduction emphasized={false}>
						Cloud Fleet Manager has been designed to seamlessly integrate with a wide
						range of other systems, allowing for smooth and efficient workflows. Whether
						you need to integrate with already existing software in your company, would
						like to link your data to third-party solutions or pass it on to e.g.
						accounting systems, our solution has the flexibility and capability to do
						so. With our software, you can easily connect and automate processes,
						streamline your operations, and increase productivity. Explore our overview
						of integrations and interfaces to learn more about our capabilities and how
						they can benefit your business.
					</Introduction>

					<Grid className="my-spacer-xl">
						<aside className="hidden lg:block col-span-12 lg:col-span-4 xl:col-span-3 sticky self-start top-[100px] md:top-[120px]">
							<nav className="flex flex-col gap-10">
								<ol>
									{integrations.nodes
										.filter(({ integrations }) => integrations.length > 0)
										.map(({ seo: { title } }, index) => (
											<li
												className="h-[34px]"
												key={`integration-toc-${slug(title)}`}>
												<a
													// href={`#${slug(title)}`}
													onClick={() => {
														scrollTo(`#${slug(title)}`)
													}}
													onKeyDown={() => {
														scrollTo(`#${slug(title)}`)
													}}
													// [&.active]:text-tealblue text-sirocco [&.active]:font-bold font-medium
													className="active:text-tealblue text-sirocco active:font-bold font-medium hover:text-pictonblue text-base hover:cursor-pointer transition-all ease-in-out">
													{title}
												</a>
											</li>
										))}
								</ol>
							</nav>
						</aside>
						<div className="col-span-12 lg:col-span-8 xl:col-span-9">
							{integrations.nodes
								.filter(({ integrations }) => integrations.length > 0)
								.map(({ seo: { title }, integrations }) => (
									<section
										className="pb-spacer-lg"
										id={slug(title)}
										key={slug(title)}>
										<h3 className="mb-30 display2">{title}</h3>
										<div className="grid grid-cols-1 xl:grid-cols-3 gap-20">
											{integrations
												.sort((a, b) => {
													return a.name.localeCompare(b.name)
												})
												.map(({ name, description, logo }) => {
													return (
														<div className="w-full border-solid border-[1px] rounded-[10px] border-silversand px-[14px] py-[17px] flex flex-row gap-16">
															{logo?.localFile && (
																<div className="w-50 h-50 flex items-center justify-center overflow-hidden bg-porcelain">
																	<GatsbyImage
																		image={getImage(
																			logo.localFile,
																		)}
																		className="bg-white"
																		alt={title}
																		title={title}
																		objectPosition="center center"
																	/>
																</div>
															)}
															<div className="flex flex-col overflow-hidden">
																<p
																	className="text-jetblack font-semibold lg:truncate"
																	title={name}
																	alt={name}>
																	{name}
																</p>
																<p
																	className="text-nevada mt-2 lg:truncate"
																	title={description}
																	alt={description}>
																	{description}
																</p>
															</div>
														</div>
													)
												})}
										</div>
									</section>
								))}
						</div>
					</Grid>
				</SafeAreaWrapper>
			</div>

			{page?.banner && (
				<BlueBanner
					className="my-spacer-xl"
					title={page?.banner.title}
					subtitle={page?.banner.pretext}
					callToAction="Contact us"
					callToActionUrl="mailto:sales@hanseaticsoft.com"
					secondaryCallToAction={
						page?.banner.download ? page?.banner.download?.title : null
					}
					secondaryCallToActionEvent="download-product-factsheet-integrations"
					secondaryCallToActionUrl={
						page?.banner.download
							? page?.banner.download?.file?.localFile?.publicURL
							: null
					}
				/>
			)}
		</Layout>
	)
}

export const pageQuery = graphql`
	query {
		page: strapiIntegrationsPage {
			seo {
				title
				description
				keywords
			}

			banner {
				title
				pretext
				download {
					title
					file {
						alternativeText
						localFile {
							publicURL
						}
					}
				}
			}
		}

		integrations: allStrapiProduct(sort: { fields: seo___title }) {
			nodes {
				seo {
					title
				}
				product_package {
					title
				}
				integrations {
					name
					description
					logo {
						localFile {
							childImageSharp {
								gatsbyImageData(
									layout: FIXED
									width: 50
									height: 50
									backgroundColor: "transparent"
									transformOptions: { fit: CONTAIN }
								)
							}
						}
					}
				}
			}
		}
	}
`

export default IntegrationsPage
